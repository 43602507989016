import React, { Component } from 'react'
import { Animate } from 'react-animate-mount'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Box } from './Box'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { space } from 'styled-system'

const Div = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  width: 100%;
  color: ${props => props.theme.color.greyShade5};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.color.brandPrimary};
  }
  &:hover:after {
    border-top: 7px solid ${props => props.theme.color.brandPrimary};
  }
  &.is-open:after {
    transform: rotate(-180deg);
  }
  &:after {
    content: '';
    margin-left: 5px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 7px solid ${props => props.theme.color.greyShade5};
    display: inline-block;
    transition: transform ${props => props.theme.transition};
    margin-bottom: 2px;
  }
`

export class Collapsible extends Component {
  state = {
    open: this.props.open || false
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  render() {
    const { trigger, className, children, ...rest } = this.props

    const { open } = this.state
    return (
      <Box {...pick(rest, [...Object.keys(space)])}>
        <Div
          className={cn(className, {
            trigger: true,
            'is-open': open
          })}
          onClick={this.toggle}
          {...omit(rest, [...Object.keys(space)])}
        >
          {trigger}
        </Div>
        <Animate show={open}>{children}</Animate>
      </Box>
    )
  }
}
Collapsible.propTypes = {
  open: PropTypes.bool,
  trigger: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node
}
export default Collapsible
