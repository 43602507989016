import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Collapsible } from '../js/components/Collapsible';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "collapsible"
    }}>{`Collapsible`}</h1>
    <p><inlineCode parentName="p">{`import { Collapsible } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`The collapsible component takes in a trigger and children components. On click of the trigger it uses the React library `}<a parentName="p" {...{
        "href": "https://github.com/nkbt/react-collapse"
      }}>{`react-collapse`}</a>{` along with some CSS styles to slide the children open/closed.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Collapsible} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <div style={{ minHeight: \'200px\' }}>\n    <Collapsible trigger=\"Click me to show content\">\n      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\n      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim\n      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea\n      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate\n      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint\n      occaecat cupidatat non proident, sunt in culpa qui officia deserunt\n      mollit anim id est laborum.\n    </Collapsible>\n  </div>\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Collapsible,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
  <div style={{
          minHeight: '200px'
        }}>
    <Collapsible trigger="Click me to show content" mdxType="Collapsible">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Collapsible>
  </div>
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      